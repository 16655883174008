<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--begin::Card-->
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                Ajouter un rôle
              </h3>
              <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                Modifier un rôle
              </h3>
            </div>
            <!--begin::Form-->
            <form class="form" @submit.prevent="save">
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Nom:</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="eg: ADM"
                      v-model="form.name"
                      :class="{ 'is-invalid': errors.name }"
                    />
                    <span class="form-text text-danger" v-if="errors.name">{{
                      errors.name[0]
                    }}</span>
                  </div>
                  <div class="col-lg-6">
                    <label>Signification:</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="eg: admin"
                      v-model="form.slug"
                      :class="{ 'is-invalid': errors.slug }"
                    />
                    <span class="form-text text-danger" v-if="errors.slug">{{
                      errors.slug[0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-lg-12 text-right">
                    <button type="submit" class="btn btn-primary mr-2">
                      Enregistrer
                    </button>
                    <button type="reset" class="btn btn-secondary">
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
          </div>
          <!--end::Card-->
        </div>
      </div>
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Form",
  data() {
    return {
      initialize: "role/create",
      store: "role",
      method: "post",
      form: {},
      success: false,
      has_error: false,
      error: "",
      errors: {}
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "role/" + this.$route.params.id + "/edit";
      this.store = "role/" + this.$route.params.id;
      this.method = "put";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
      });
    },
    save() {
      let vm = this;
      ApiService[this.method](this.store, this.form)
        .then(function(response) {
          if (response.data.success) {
            if (vm.$route.meta.mode === "create") {
              Swal.fire({
                title: "Réussie",
                text: "Le rôle a été enrégistré.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            } else {
              Swal.fire({
                title: "Réussie",
                text: "Le rôle a été modifié avec succès.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            }
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style scoped></style>
